<template>
  <v-container class="grid-schedule__container">
    <ScheduleViewOptions v-if="adminView" />
    <div v-if="adminView && numHiddenEvents > 0">
      <v-checkbox v-model="showHiddenEvents" :label="`Show hidden events? (${numHiddenEvents})`"></v-checkbox>
    </div>
    <div v-if="allLocations && myEvents && myEvents.length" class="tables-wrapper">
      <div class="tables-wrapper__single" v-for="(locationGroup, i) in locationsGrouped" :key="`group-${i}`">
        <table class="grid-schedule">
          <th class="day"></th>
          <th
            v-for="(location, i) in locationGroup"
            :key="`loc-heading-${i}`"
            class="primary white--text"
          >
            <h3>{{ location.name }}</h3>
          </th>

          <tbody v-for="(day) in eventsByDateAndHour(i)" :key="day[0]">
            <tr class="grid-schedule__day-header accent white--text">
              <th :colspan="locationGroup.length + 1">{{ formatIsoDate(day[0]) }}</th>
            </tr>
            <tr v-for="(eventGroup, j) in day[1]" :key="`row-${j}`" class="grid-schedule__row">
              <td class="grid-schedule__time primary white--text">{{ militaryToAmPm(`${eventGroup[0].split('-')[0]}`, false) + '-' + militaryToAmPm(`${eventGroup[0].split('-')[1]}`) }}</td>
              <td v-for="(location, k) in locationGroup" :key="`cell-${j}-${k}`" class="background">
                <p class="event-name" v-for="(event, l) in getEvents(eventGroup[1], location)" :key="`event-${j}-${k}-${l}`">
                  <span v-if="adminView && event.hideOnGlobalSchedule">
                    <v-icon color="primary" class="hidden-event-marker">mdi-domino-mask</v-icon>
                  </span>
                  {{ event.description }}

                  <v-icon v-if="adminEditor" color="error" class="pa-1 edit-icon" @click="editEvent(event)">
                    mdi-pencil
                  </v-icon>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <v-progress-circular v-else-if="!eventsInitialized" indeterminate size="80" color="primary"></v-progress-circular>
    <p v-else>It looks like there aren't any relevant events.</p>
    <v-btn class="grid-schedule__export primary" @click="exportCsv">Export to CSV <v-icon right>mdi-export</v-icon></v-btn>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import ScheduleViewOptions from '@/components/ScheduleViewOptions'

export default {
  name: 'GridSchedule',

  components: {
    ScheduleViewOptions
  },

  data: () => ({
    showHiddenEvents: false
  }),

  computed: {
    ...mapGetters([
      'allLocations',
      'myEvents',
      'numHiddenEvents',
      'userRole',
      'eventsInitialized'
    ]),
    filteredEvents () {
      // Only show events for which hideOnGlobalSchedule is not set / false
      if (this.myEvents && !this.showHiddenEvents) {
        return this.myEvents.filter((event) => !event.hideOnGlobalSchedule)
      } else {
        return this.myEvents
      }
    },
    locationsGrouped () {
      const grouped = {
        'group-1': [] // Default to group-1
      }
      this.allLocations.forEach((location) => {
        if (location.group) {
          if (!grouped[location.group]) grouped[location.group] = []
          grouped[location.group].push(location)
        } else {
          grouped['group-1'].push(location)
        }
      })
      return grouped
    },
    adminView () {
      return this.userRole === 'admin' || this.userRole === 'viewer'
    },
    adminEditor () {
      return this.userRole === 'admin'
    }
  },

  methods: {
    ...mapActions(['globalFlagsListener']),
    getEvents (eventGroup, location) {
      const events = eventGroup.filter((event) => event.location === location.id)
      return events
    },
    // Figure out if this location is in this group
    locationInGroup (location, group) {
      let inGroup = false
      group.forEach((loc) => {
        if (loc.id === location) {
          inGroup = true
        }
      })
      return inGroup
    },
    eventsByDateAndHour (group) {
      if (!this.filteredEvents || !this.filteredEvents.length) return {}
      const events = {}
      this.filteredEvents.forEach((event) => {
        // We only want to get events / times that are associated with locations in this particular location group
        if (event.start && event.end && event.date && (this.locationInGroup(event.location, this.locationsGrouped[group]) || (!event.group && group === 'group-1'))) {
          // console.log(`Event Start: ${event.start} | Event End: ${event.end} | Event Date: ${event.date}`)
          // The combined start / end times will be used as a key for grouping events in the grid
          const timeSpan = event.start + '-' + event.end
          if (!events[event.date]) events[event.date] = {}
          if (!events[event.date][timeSpan]) events[event.date][timeSpan] = []
          events[event.date][timeSpan].push(event)
        }
      })
      const eventArray = Object.entries(events)
      // Now sort by date
      const sorted = eventArray.sort((a, b) => {
        const dateA = new Date(a[0])
        const dateB = new Date(b[0])
        return dateA - dateB
      })

      // Then, for each day, sort by hour
      sorted.forEach((day) => {
        const dayEvents = day[1]
        const dayEventsArray = Object.entries(dayEvents)
        const sortedDayEvents = dayEventsArray.sort((a, b) => {
          return parseInt(a[0]) - parseInt(b[0])
        })
        day[1] = sortedDayEvents
      })
      // console.log('Sorted events', sorted)
      // Note that we're returning an array of arrays, not an object, to retain order
      return sorted
    },
    editEvent (event) {
      this.$root.$emit('showEventEditor', event)
    },
    formatForCsvExport (inputData, group) {
      let csvData = ',' // Start with empty column
      this.locationsGrouped[group].forEach((loc, i) => {
        // Note that we need to wrap these in double quotes so that line breaks within titles
        // don't create new rows.
        csvData += `"${loc.name}"` + (i < this.locationsGrouped[group].length - 1 ? ',' : '\n')
      })

      inputData.forEach((day) => {
        csvData += `"${this.formatIsoDate(day[0])}"` + '\n'
        day[1].forEach((eventGroup) => {
          csvData += this.militaryToAmPm(`${eventGroup[0].split('-')[0]}`, false) + '-' + this.militaryToAmPm(`${eventGroup[0].split('-')[1]}`) + ','

          this.locationsGrouped[group].forEach((loc, i) => {
            this.getEvents(eventGroup[1], loc).forEach((event) => {
              csvData += `"${event.description}${event.sessionDescription ? '\n-------\n' + event.sessionDescription : ''}"`
            })
            if (i < this.locationsGrouped[group].length - 1) csvData += ','
          })
          csvData += '\n'
        })
      })
      return csvData
    },
    exportCsv () {
      console.log('Exporting CSV...')

      // Format the data and convert to CSV
      for (const [key] of Object.entries(this.locationsGrouped)) {
        console.log('Exporting', key)
        const eventsFormatted = this.formatForCsvExport(this.eventsByDateAndHour(key), key)
        console.log('Events Formatted:', eventsFormatted)
        // Now convert to file to download.
        const blob = new Blob([eventsFormatted], { type: 'text/csv' })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        const timestamp = new Date().toISOString().replace(/T/, '_').replace(/:/g, '-').split('.')[0] + 'UTC'
        link.download = `grid-schedule_${key}_${timestamp}.csv`
        link.click()
      }
    }
  },

  async created () {
    await this.globalFlagsListener()
  },

  mounted () {
    console.log('Locations', this.allLocations)
    console.log('My Events', this.myEvents)
  }
}
</script>

<style lang="scss" scoped>
.grid-schedule {
  width: 100%;
  margin-bottom: 20px;

  &__container {
    max-width: 1920px;

    .v-progress-circular {
      display: block;
      margin: 0 auto;
    }
  }

  &__time {
    font-size: 14px;
    min-width: 100px;
  }

  &__day-header {
    th {
      text-align: left !important;
      padding: 8px;
    }
  }

  &__export {
    margin-bottom: 60px;

    @media print {
      display: none;
    }
  }

  th {
    text-align: center;
    border: solid 1px transparent !important;
    font-size: 14px;
    line-height: 1.2em;
    padding: 4px;
  }
  tr {
    td {
      padding: 4px;
      position: relative;

      .event-name {
        position: relative;
        line-height: 1.2em;
        font-size: 12px;
        margin: 0;
      }

      .edit-icon {
        position: absolute;
        top: 0;
        right: -24px;
        transform: translate(-50%, -50%);
        display: none;
      }

      &:hover {
        .edit-icon {
          display: block;
        }
      }
    }
    td:first-child {
      text-align: right;
    }
    td:not(:first-child) {
      text-align: center;
    }
  }
}
</style>
